import React, { useEffect, useState } from "react";
import { UserGroupIcon } from "@heroicons/react/24/outline";
import DashboardCard from "./card";

const fetchGreed = () => fetch(`https://colintalkscrypto.com/cbbi/data/latest.json`).then((res) => res.json());



const getLastValue = (objectArray: any, index: any) => {
  const value = objectArray[index];
  return (value * 100).toFixed(0) + "%"
}

const CbbiCard: React.FC = () => {
  const [value, setIndex] = useState('-');

  useEffect(() => {
    fetchGreed().then((data) => {
      const index = Object.keys(data.Confidence)[Object.keys(data.Confidence).length - 1]
      setIndex(getLastValue(data.Confidence, index));
    });
  }, []);

  return (
    <DashboardCard
      title="ColinTalksCrypto Bitcoin Bull Run Index"
      value={value}
      description={'Befinden wir uns beim Top von einem Bullrun?'}
      tooltip="Der Colin Talks Crypto Bitcoin Bull Run Index vereint mehrere On-Chain Metriken zu einem Wert. Ziel ist es das Top von einem Bullrun zu identifizieren. Ab einen Wert von 90 befinden wir uns sehr Wahrscheinlich bei einem Top."
      icon={<UserGroupIcon className="h-10 w-10 text-blue-800" />}
      href="https://colintalkscrypto.com/cbbi/"
    />
  );
};

export default CbbiCard;
