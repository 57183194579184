/* eslint-disable @typescript-eslint/ban-ts-comment */
import React, { useEffect } from "react";
import useScript from "react-script-hook";

const url = "https://ssl.gstatic.com/trends_nrtr/2051_RC11/embed_loader.js";

type Props = {
  type?: string;
  keywords: string[];
  time: string;
  geo?: string;
};

const GoogleTrends: React.FC<Props> = ({ type = "TIMESERIES", keywords = [], geo = "", time }) => {
  const id = `widget-${keywords.join("-")}`;
  const [loading, error] = useScript({
    src: url,
    checkForExisting: true,
  });
  useEffect(() => {
    if (!loading) handleScriptLoad();
  }, [keywords, loading]);

  const handleScriptLoad = () => {
    //@ts-ignore
    if (!window.trends || error) return;

    const elm = document.getElementById(id)
    if (elm) {
      elm.innerHTML = "";
    }

    //@ts-ignore
    window.trends.embed.renderExploreWidgetTo(
      document.getElementById(id),
      type,
      {
        comparisonItem: keywords.map((keyword) => ({ keyword, geo, time })),
        category: 0,
        property: "",
      },
      {
        exploreQuery: `q=${encodeURI(keywords.join(","))}&date=${time}`,
        guestPath: "https://trends.google.com:443/trends/embed/",
      }
    );
  };

  if (loading) return <div>Loading google script</div>;

  return <div id={id} className="googleTrend"></div>;
};
export default GoogleTrends;
