import React, { ComponentProps, useState } from "react";
import Link, { getStyle } from "../../UI-Elements/Navigation/Link";
import Card from "../../UI-Elements/Card";

type TimeRange = "30days" | "60days" | "180days" | "1year" | "3years" | "all";
type Props = {
  slug: string;
  timeRange?: TimeRange;
  h?: string;
  w?: string;
} & ComponentProps<typeof Card>;

const Button: React.FC<React.ButtonHTMLAttributes<HTMLButtonElement>> = ({ onClick, children }) => {
  const baseClass = getStyle("text");
  return (
    <button onClick={onClick} className={`${baseClass} mr-2`}>
      {children}
    </button>
  );
};

const BlockchainComCard: React.FC<Props> = ({ slug, title, timeRange = "30days", h = "405", w = "720", ...others }) => {
  const [timespan, setTimespan] = useState<TimeRange>(timeRange);
  const href = `https://blockchain.info/charts/${slug}`;
  const imageSrc = `https://api.blockchain.info/charts/preview/${slug}.png?timespan=${timespan}&h=${h}&w=${w}&daysAverageString=7D`;

  return (
    <Card title={title} href={href} {...others}>
      <Link href={href} external>
        <img src={imageSrc} alt={`Chart ${title}`}/>
      </Link>
      <div className="flex justify-between text-sm mt-2">
        <div>
          <Button onClick={() => setTimespan("30days")}>30d</Button>
          <Button onClick={() => setTimespan("1year")}>1y</Button>
          <Button onClick={() => setTimespan("3years")}>3y</Button>
        </div>
        <p className="text-right m-0">
          Quelle:{" "}
          <Link href={href} type="text" external>
            Blockchain.com
          </Link>
        </p>
      </div>
    </Card>
  );
};

export default BlockchainComCard;
