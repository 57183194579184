import React from "react";

export type ProgressBarItem = {
}
type Props = {
  value: number; // in percent
  colorClass?: string;
  label?: string;
};

const ProgressBar: React.FC<Props> = ({ value, label, colorClass = 'bg-sky-600' }) => {
  return (
    <div className="relative pt-2">
      <div className="overflow-hidden h-3 mb-4 text-xs flex rounded bg-sky-300">
          <div
            key={value}
            style={{ width: `${value}%` }}
            className={`shadow-none flex flex-col text-center whitespace-nowrap text-white justify-center ${colorClass}`}
          >
            {label ? label : ''}
          </div>
      </div>
    </div>
  );
};

export default ProgressBar;
