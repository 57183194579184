import React from "react";
import LinkList from "../../UI-Elements/Lists/LinkList";

const links = [
  { href: "https://glassnode.com/", title: "Glassnode" },
  { href: "https://www.lookintobitcoin.com/charts/", title: "Looking into Bitcoin" },
  { href: "https://www.blockchaincenter.net/altcoin-season-index/", title: "Altcoin Season Index" },
  { href: "http://charts.woobull.com/", title: "Woobull Charts" },
  { href: "https://casebitcoin.com/", title: "The case bitcoin" },
  { href: "https://datamish.com/", title: "Long and Shorts" },
  { href: "https://decentrader.com/live-charts/#charts-section--market-cycle", title: "DecentTrader Charts" },
  { href: "https://cryptoquant.com/asset/btc/chart/exchange-flows", title: "CryptoQuant Charts" },
  { href: "https://stats.buybitcoinworldwide.com/", title: "Buy Bitcoin Worldwide Stats" },
  { href: "https://www.coinglass.com/pro/i/bitcoinBubble", title: "Bitcoin Bubble Index" },
  { href: "https://bitcoinvisuals.com/resources", title: "Bitcoin Visuals Resources" },
  { href: "https://checkonchain.com/", title: "Check on chain" },
];

const BTCLinks: React.FC = () => {
  return <LinkList links={links} />;
};

export default BTCLinks;
