import React from "react";

import Layout from "../../components/layout";
import SEO from "../../components/seo";
import CbbiCard from "../../components/Modules/dashboard/cbbi";
import CoinPrice from "../../components/Modules/dashboard/coin-price";
import GoogleTrends from "../../components/UI-Elements/GoogleTrend";
import Card from "../../components/UI-Elements/Card";
import LinkList from "../../components/Modules/dashboard/link-list";
import Link from "../../components/UI-Elements/Navigation/Link";
import BTCStats from "../../components/Modules/dashboard/btc-stats";

import BlockchainComCard from "../../components/Modules/dashboard/blockchain-com";
import Title from "@components/UI-Elements/Typography/Title";
import { Section } from "@components/UI-Elements/General";

const title = "Bitcoin Dashboard";
const description =
  "Auf dem Bitcoin Dashboard sind wichtige Kennzahlen über das Netzwerk und den Markt zusammen gefasst.";
const Dashboard = () => {
  return (
    <Layout prose>
      <SEO
        keywords={["dashboard", "bitcoin"]}
        title={title}
        description={description}
      />
      <div className="text-center">
        <Title>{title}</Title>
        <p>{description}</p>
      </div>

      <Section className="py-8">
        <Title level={2}>Was sagt der Markt?</Title>
        <div className="md:flex">
          <div className="basis-1/3 md:pr-2">
            <Card>
              <Link
                href="https://www.lookintobitcoin.com/charts/bitcoin-fear-and-greed-index/"
                external
              >
                <img src="https://alternative.me/crypto/fear-and-greed-index.png" />
              </Link>
            </Card>
          </div>
          <div className="grid basis-auto gap-4 md:basis-2/3 md:grid-cols-2 md:pl-2">
            <CbbiCard />
            <CoinPrice coin="Bitcoin" />
            <Card
              title="Altcoin Index"
              href="https://www.blockchaincenter.net/altcoin-season-index/"
            >
              <p>
                Befinden wir uns gerade in einem Bitcoin oder Altcoin Markt?
              </p>
            </Card>
            <Card
              title="Monatliche Renditen"
              href="https://www.coinglass.com/today"
            >
              <p>
                War dieser Monat in der Vergangenheit eher ein starker oder
                schwacher Monat?{" "}
                <Link
                  href="https://bitcoinmonthlyreturn.com/"
                  external
                  type="text"
                >
                  bitcoinmonthlyreturn.com
                </Link>{" "}
                <Link
                  href="https://www.coinglass.com/today"
                  external
                  type="text"
                >
                  coinglass.com/today
                </Link>
              </p>
            </Card>
          </div>
        </div>
      </Section>

      <Section className="py-8">
        <Title level={2}>Wie ist der Zustand vom Netzwerk?</Title>
        <div className="mb-5 grid gap-4 md:grid-cols-2 lg:grid-cols-3">
          <Card title="Memepool.space" href="https://mempool.space/de/">
            <p>
              Wie viele Transaktionen sind im aktuellen Block? Wie hoch sind die
              Gebühren?
            </p>
          </Card>
          <BTCStats />
          <BlockchainComCard
            title="Aktuelle Hashrate"
            slug="hash-rate"
            tooltip="Die Hashrate ist ein wichtiger Teil der Netzwerksicherheit. Sinkt diese um 50% kann das Netzwerk leicht angegriffen werden."
          />
          <BlockchainComCard
            title="Transaktionskosten in USD"
            slug="transaction-fees-usd"
            tooltip="Die reinen Transaktionskosten, also ohne Blockbelohnung welche an die Miner gezahlt werden."
          />
          <BlockchainComCard
            title="Einnahmen der Miner in USD"
            slug="miners-revenue"
            timeRange="30days"
            tooltip="Die gesamt Einnahmen der Miner also Transaktionskosten plus Blockbelohnung."
          />
          <BlockchainComCard
            title="Wallet Benutzer"
            slug="my-wallet-n-users"
            timeRange="30days"
            tooltip="Anzahl der Wallet Adressen. Diese Zahl spiegelt nicht die Nutzer wieder, weil ein Nutzer mehrere Adressen erstellen kann."
          />
        </div>
      </Section>

      <Section className="py-16">
        <Title level={2}>Weitere Infos zu Bitcoin</Title>
        <div className="grid gap-4 md:grid-cols-2">
          <Card title="Links & Resources">
            <LinkList />
          </Card>
          <Card
            title="Wie oft wurde Bitcoin für Tot erklärt?"
            href="https://www.bitcoinisdead.org/"
          >
            Findest du auf{" "}
            <Link href="https://www.bitcoinisdead.org/" external type="text">
              bitcoinisdead.org
            </Link>{" "}
            oder{" "}
            <Link
              href="https://99bitcoins.com/bitcoin-obituaries/"
              external
              type="text"
            >
              99bitcoins.com
            </Link>
            .
          </Card>
          <Card
            title="Welche Unternehmen und Personen besitzen Bitcoin?"
            href="https://www.buybitcoinworldwide.com/treasuries/"
          >
            <Link
              href="https://www.buybitcoinworldwide.com/treasuries/"
              type="text"
              external
            >
              Bitcoin Treasuries
            </Link>
          </Card>
        </div>
      </Section>
      <div className="mt-5 flex flex-col">
        <Title level={2}>
          Wie viele Leute interessieren sich gerade für Bitcoin?
        </Title>
        <Card title="Bitcoin vs. Ethereum">
          <GoogleTrends time="today 5-y" keywords={["Bitcoin", "Ethereum"]} />
        </Card>
      </div>
    </Layout>
  );
};

// Liste mit Links, Looking into bitcoin, Luna Score
// Korreliert der Markt mit den Aktien?

// https://bitcoinmonthlyreturn.com/
// https://www.coinglass.com/pro/i/bitcoinBubble
// https://checkonchain.com/

// https://bitcoinvisuals.com/resources
// https://cryptomaniaks.com/guides/best-crypto-tools-checklist

// Glossare für Posts
// https://coinmarketcap.com/alexandria/glossary
// https://www.bitcoinsuisse.com/de/fundamentals/krypto-glossar
// https://cvj.ch/glossar/
export default Dashboard;

// https://martechwithme.com/monitoring-youtube-channels-subscribers-with-google-sheets/
