import React, { useEffect, useState } from "react";
import DashboardCard from "./card";
import ProgressBar from "../../UI-Elements/ProgressBar";

const fetchBlockInfo = () => fetch(`https://blockchain.info/stats?format=json`).then((res) => res.json());

type Props = {
  coin?: string;
};
type Stats = {
  remainingBlocks: number;
  currentBlock: number;
  totalBtc: number;
};
type NextHalving = {
  minutesLeft?: number;
  hoursLeft?: number;
  daysLeft?: number;
  yearsLeft?: number;
  remainingBlocks?: number;
  estimatedDate?: Date
};
const nextHalving = 840000;
// const blockTime = 9.69166666667;

const BTCStats: React.FC<Props> = () => {
  const [blockStats, setBlockStats] = useState<Stats>({
    currentBlock: 0,
    remainingBlocks: 0,
    totalBtc: 0
  });
  const [halving, setHalving] = useState<NextHalving>({});
  const [avgBlockTime, setAvgBlockTime] = useState<number>(0);

  useEffect(() => {
    fetchBlockInfo().then((data) => {
      const currentBlock = data.n_blocks_total;
      const remainingBlocks = nextHalving - currentBlock;
      const totalBtc = data.totalbc;

      setBlockStats({
        currentBlock,
        remainingBlocks,
        totalBtc
      });
      setAvgBlockTime(data.minutes_between_blocks)
    });

    // fetchBlockTime().then((data) => setAvgBlockTime(data));
  }, []);

  useEffect(() => {
    const minutesLeft = Math.floor(blockStats.remainingBlocks * avgBlockTime); // seconds
    const hoursLeft = Math.floor(minutesLeft / 60);
    const yearsLeft = Math.floor(minutesLeft / (24 * 60 * 365))
    const daysLeft = Math.floor((minutesLeft / (60 * 24) - yearsLeft * 365));
    const estimatedDate = new Date(Date.now() + (minutesLeft * 60 * 1000));
    const halving = {
      minutesLeft,
      hoursLeft,
      daysLeft,
      yearsLeft,
      estimatedDate
    };
    setHalving(halving);
  }, [avgBlockTime, blockStats]);
  
  const blockProgress = (blockStats.remainingBlocks/210000 * 100)
  const mioBtc = blockStats?.totalBtc / 100000000000000
  const remainingBtc = (21000000 - (blockStats?.totalBtc / 100000000)) / 1000000
  const btcProgress = (blockStats?.totalBtc/(21 * 1000000000000))
  
  return (
    <>
      <DashboardCard
        title={`Nächstes Halving in`}
        value={`${halving?.yearsLeft} Jahre ${halving?.daysLeft?.toFixed(0) ?? "-"} Tage`}
        description={`Geschätzter Tag: ${halving?.estimatedDate?.toLocaleDateString('de-DE')}*`}
        tooltip="Beim Bitcoin Halving wird die Blockbelohnung halbiert, was dazu führt dass die Anzahl an neu erzeugten Bitcoins halbiert wird. Dieses Ereignis tritt alle 210000 Blöcke auf. Die Zeit bis ein neuer Block gefunden wird variiert und hängt von der aktuellen Schwierigkeit und der gesamten Hashrate des Netzwerks ab. Das Halving Datum kann daher nur geschätzt werden."
      />
      <DashboardCard
        title="Aktueller Block"
        value={blockStats?.currentBlock?.toFixed(0)}
        description={`Wir sind noch ${blockStats?.remainingBlocks} Blöcke vom nächsten Halving entfernt`}
        tooltip={`In anderen Worten besteht die Bitcoin Blockchain aus ${blockStats?.currentBlock?.toFixed(0)} aneinandergereihten Blöcken. Alle 210000 Blöcke ereignet sich das so genannte Bitcoin Halving.`}
      >
        <ProgressBar value={blockProgress} label={`${blockProgress.toFixed()} %`} />
      </DashboardCard>
      <DashboardCard
        title="Verfügbare Bitcoins"
        value={`${(mioBtc).toLocaleString('de-DE', { maximumFractionDigits: 2 })} Millionen`}
        description={`Über die Zeit werden noch ${remainingBtc.toFixed(2)} Millionen Bitcoins erstellt`}
        tooltip="Die Menge an erzeugten Bitcoins ist auf ca. 21 Millionen begrenzt."
      >
        <ProgressBar value={btcProgress} label={`${btcProgress.toFixed()} %`} />
      </DashboardCard>
      {/* <DashboardCard
        title="⦰ Block Zeit"
        value={`${Math.floor(avgBlockTime)} sec`}
        description={`alle ${(avgBlockTime / 60).toFixed(0)} Minuten`}
        icon={<BeakerIcon className="h-10 w-10 text-red-500" />}
        >
      </DashboardCard> */}
    </>
  );
};

export default BTCStats;


// https://medium.com/blockchain/using-the-blockchain-api-to-determine-the-time-left-until-the-next-bitcoin-block-halving-10e2a170e75f
// The Bitcoin halving prediction is based on the latest block height of 719,731 and the average block time for the last one thousand blocks, which is currently at 538 seconds per block.
// https://www.coinwarz.com/mining/bitcoin/halving

// const fetchBlockTime = () => fetch(`https://blockchain.info/q/interval`).then((res) => res.json());